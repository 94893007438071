<template>
  <div class="investment-main-container">
    <div class="investment-header-container">
      <div class="header-title">招商信息</div>
      <div class="header-slogan">合作共创未来  打造行业标杆</div>
    </div>
    <div class="investment-body-container">
      <div class="body-header-container">
        <div
          class="body-header-nav"
          :class="item.class"
          :data-id="index"
          :ref="index"
          @click="clickNav"
          v-for="(item,index) in zhaoshangNavItems"
          :key="index"
        >{{item.name}}</div>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import UtilPaths from '../utils/UtilsPath'
import axios from 'axios'
export default {
  data () {
    return {
      zhaoshangNavItems: [{ name: '招商方案' }, { name: '已签约品牌' }],
      indexInfos: ''
    }
  },
  methods: {
    clickNav (e) {
      for (let i in this.$refs) {
        if (this.$refs[i][0].classList.contains('body-header-nav-clicked')) {
          this.$refs[i][0].classList.remove('body-header-nav-clicked')
        }
      }
      e.currentTarget.classList.add('body-header-nav-clicked')
      // this.$data.indexInfos = parseInt(e.srcElement.dataset.id)
      // let tIndex = JSON.stringify(this.$data.indexInfos)
      // console.log(tIndex)
      // sessionStorage.setItem('InvestIndex', tIndex)
      if (e.srcElement.innerText === '招商方案') {
        this.$router.push({ name: 'ZiInvestmentInfo', params: { index: e.srcElement.dataset.id } })
      } else if (e.srcElement.innerText === '已签约品牌') {
        this.$router.push({ name: 'ZiInverstmentParter', params: { index: e.srcElement.dataset.id } })
      }
    }
  },
  async created () {
    this.$data.indexInfos = this.$route.params.index
    let tempZhaoshangInfos = await axios.get(UtilPaths.getBussinessParent)
    this.$store.commit('setZhaoshangItems', { zhaoshangItems: tempZhaoshangInfos.data })
  },
  mounted () {
    let tIndex = JSON.stringify(this.$route.params.index)
    if (!tIndex) {
      tIndex = sessionStorage.getItem('InvestIndex')
    } else {
      sessionStorage.setItem('InvestIndex', tIndex)
    }
    this.$refs[tIndex][0].classList.add('body-header-nav-clicked')
    // if (this.$route.params.index === 0) {
    //   let tIndex = JSON.stringify(this.$route.params.index)
    //   sessionStorage.setItem('InvestIndex', tIndex)
    //   console.log(tIndex)
    //   this.$refs[tIndex][0].classList.add('body-header-nav-clicked')
    // } else {
    //   let tIndex = sessionStorage.getItem('InvestIndex')
    //   console.log(tIndex)
    //   this.$refs[this.$route.params.index][0].classList.add('body-header-nav-clicked')
    // }
  },
  watch: {
    name () {
      for (let i in this.$refs) {
        if (this.$refs[i][0].classList.contains('body-header-nav-clicked')) {
          this.$refs[i][0].classList.remove('body-header-nav-clicked')
        }
      }
      if (this.$route.params.index) {
        let tIndex = JSON.stringify(this.$route.params.index)
        sessionStorage.setItem('InvestIndex', tIndex)
        this.$refs[this.$route.params.index][0].classList.add('body-header-nav-clicked')
      } else {
        this.$refs[this.$route.params.index][0].classList.add('body-header-nav-clicked')
      }
      // else {
      //   console.log(this.$data.indexInfos)
      //   this.$refs[this.$data.indexInfos][0].classList.add('body-header-nav-clicked')
      // }
    }
  },
  computed: {
    name () {
      return this.$route.params.index
    }
  }
}
</script>

<style scoped>
.investment-main-container {
  display: flex;
  flex-direction: column;
}
.investment-header-container {
  background: url('https://pc.hnchengyue.com/static/image/bigbackground/businbigbg.png');
  background-size: 100% 23.92rem;
  height: 23.92rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.header-title {
  color: #fff;
  font-size: 2.85rem;
  letter-spacing: 6px;
}
.header-slogan {
  /* width: 22.64rem; */
  color: #fff;
  font-size: 1.14rem;
  letter-spacing: 6px;
  margin-top: 2.67rem;
  opacity: 0.8;
  border-bottom: 1px #fff solid;
}
.investment-body-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.body-header-container {
  display: flex;
  margin-top: 2.21rem;
  height: 2.85rem;
}
.body-header-nav{
  width: 10.71rem;
  height: 2.85rem;
  font-size: 1.14rem;
  margin-right: 1.13rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.body-header-nav:hover{
  font-size: 1.2rem;
  background: #b5b5b5;
  color: #fff;
}
.body-header-nav-clicked{
  color:#fff;
  background: #2d3e50;
}
</style>
