import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import CoreBussiness from '../views/CoreBussiness.vue'
import InvestmentInfo from '../views/InvestmentInfo.vue'
import UNInformationCenter from '../views/UNInformationCenter.vue'
import ServerManager from '../views/ServerManager.vue'
import appDesc from '../views/appDesc.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'ServerManager',
        component: ServerManager,
        redirect: { name: 'Home' },
        children: [{
                path: '/',
                name: 'Home',
                component: Home
            },
            {
                path: '/about',
                name: 'About',
                component: About
            },
            {
                path: '/coreBussiness/:id',
                name: 'CoreBussiness',
                component: CoreBussiness
            },
            {
                path: '/appDesc',
                name: 'appDesc',
                component: appDesc
            },
            {
                path: '/investmentInfo',
                name: 'InvestmentInfo',
                redirect: { name: 'ZiInvestmentInfo' },
                component: InvestmentInfo,
                children: [{
                        path: '/ziInvestmentInfo',
                        name: 'ZiInvestmentInfo',
                        component: () =>
                            import ('../components/InvestmentInfo/ziInvestmentInfo.vue')
                    },
                    {
                        path: '/ziInvestmentMoreInfo',
                        name: 'ZiInvestmentMoreInfo',
                        component: () =>
                            import ('../components/InvestmentInfo/ziInvestmentMoreInfo.vue')
                    },
                    {
                        path: '/ziInvestmentParter',
                        name: 'ZiInverstmentParter',
                        component: () =>
                            import ('../components/InvestmentInfo/ziInvestmentParter.vue')
                    }
                ]
            },
            {
                path: '/uninformationcenter',
                name: 'UNInformationCenter',
                redirect: { name: 'UNInfo' },
                component: UNInformationCenter,
                children: [{
                        path: 'uNInfo',
                        name: 'UNInfo',
                        component: () =>
                            import ('../components/UNInformationCenter/UNInfo.vue')
                    },
                    {
                        path: 'uNProject',
                        name: 'UNProject',
                        component: () =>
                            import ('../components/UNInformationCenter/UNProject.vue')
                    }
                ]
            }
        ]
    },
    {
        path: '/uNMoreInfo',
        name: 'UNMoreInfo',
        component: () =>
            import ('../components/UNInformationCenter/UNMoreInfo.vue')
    },
    {
        path: '/uNMoreProject',
        name: 'UNMoreProject',
        component: () =>
            import ('../components/UNInformationCenter/UNMoreProject.vue')
    }
]

const router = new VueRouter({
    routes
})

export default router