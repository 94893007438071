<template>
  <article class="home-main-container swiper-container">
    <div class="side-nav">
      <ul class="nav-side">
        <li><a ref="first" :data-id=1 @click="clickNav" class="tooltip-side-nav default select one"></a></li>
        <li><a ref="second" :data-id=2 @click="clickNav" class="tooltip-side-nav default two"></a></li>
        <li><a ref="third" :data-id=3 @click="clickNav" class="tooltip-side-nav default three"></a></li>
        <!-- <li><a ref="fourth" :data-id=4 @click="clickNav" class="tooltip-side-nav default four"></a></li> -->
        <!-- <li><a ref="fifth" :data-id=5 @click="clickNav" class="tooltip-side-nav default five"></a></li> -->
      </ul>
    </div>


    <div class="first-container" id="first">
      <!-- <div v-if="hideControll" class="zhezhao" @click="closeVideo"></div> -->
      <!-- <div class="first-title">博树科技创造</div> -->
      <img class="slide-icon" :data-id=2 @click="clickNav" src="../assets/slide_icon@2x.png">
    </div>


    <div class="second-container" id="second">
      <div class="header-nav-container">
        <div class="header-logo-img" style="color:#333333">博树</div>
      </div>
      <div class="second-business-txt-container">
        <div class="core-english">CORE BUSINESS</div>
        <div class="line"></div>
        <div class="core-chinese">核心业务</div>
        <div class="business-contain">
          <div class="contain-txt" v-for="(item,index) in hexinitems" :key="index">{{item.title}}</div>
        </div>
      </div>
      <div class="second-business-img-container">
        <div class="img-header-container">
          <!-- @click="clickCoreNav" -->
          <div class="first-img" :data-id="index"  v-for="(item, index) in topHexinItems" :key="index">
            <img class="business-img" :src="item.weburl">
            <div class="business-txt">{{item.title}}</div>
          </div>
        </div>
        <div class="img-footer-container">
          <!-- @click="clickFooterCoreNav" -->
          <div class="last-img" :data-id="index"  v-for="(item, index) in downHexinItems" :key="index">
            <img class="business-img" :src="item.weburl">
            <div class="business-txt">{{item.title}}</div>
          </div>
        </div>
      </div>
      <img class="slide-icon" :data-id=3 @click="clickNav" src="../assets/slide_icon@2x.png">
    </div>





    <div class="third-container" id="third">
      <div class="header-nav-container">
        <div class="header-logo-img" style="color:#fff">博树</div>
      </div>
      <div class="invest-container">
        <div class="core-container-left">
          <div class="core-english" style="color:#fff">INVEST</div>
          <div class="core-english" style="color:#fff;text-align:start">INFORMATION</div>
          <div class="line"></div>
          <!-- <div class="core-chinese" style="color:#fff;margin-bottom:100px;font-size:1rem;">项目招商</div> -->
          <div class="join-us-txt">加入我们</div>
          <div class="join-us-txt">共创燎原之势</div>
        </div>
        <div class="invest-info-container">
          <div class="nuclearBg"></div>
        </div>
      </div>
      <img class="slide-icon" :data-id=4 @click="clickNav" src="../assets/slide_icon@2x.png">
    </div>






    <!-- <div class="fourth-container" id="fourth">
      <div class="header-nav-container">
        <div class="header-logo-img" style="color:#333333">博树</div>
      </div>
      <div class="info-main-container">
        <div class="info-container">
          <div class="detail-info" v-for="(item, index) in projectItems" :key="index">
            <div class="time-container">
              <div class="time-day">{{item.day}}</div>
              <div class="time-date">{{item.date}}</div>
            </div>
            <div style="width:2px;height:3rem;background:#fff;margin-left:0.83rem"></div>
            <div class="info-txt-container">
              <div class="txt-title">{{item.title}}</div>
              <div class="txt-content">{{item.subtitle}}</div>
            </div>
          </div>
          <div class="more-btn more-btn-active" :data-id="1" @click="clickUNInfomationCenter">
            <div>了解更多>></div>
          </div>
        </div>
        <div class="new-info-container">
          <div class="info-time-container">
            <div class="time-day" style="color:#fff">{{unFirstInfo.day}}</div>
            <div class="time-date" style="color:#fff;position:absolute;bottom:0;left:2rem">{{unFirstInfo.date}}</div>
          </div>
          <div class="txt-title">{{unFirstInfo.title}}</div>
          <div class="txt-content" style="margin-top:0.3rem">{{unFirstInfo.subtitle}}</div>
          <div class="more-btn" :data-id="0" @click="clickUNInfomationCenter" style="margin-top:0.85rem;margin-left:0">
            <div>了解更多>></div>
          </div>
        </div>
        <div class="center-info-container">
          <div class="core-english" style="color:#999999">INFORMATION</div>
          <div class="core-english" style="color:#999999;text-align:start">CENTER</div>
          <div class="line"></div>
          <div class="core-chinese" style="color:#333333">新闻中心</div>
          <div class="business-contain" style="color:#fff">
            <div class="contain-txt" style="color:#999999" v-for="(item,index) in xinwenitems" :key="index">{{item.name}}</div>
          </div>
        </div>
        <img class="slide-icon" :data-id=5 @click="clickNav" src="../assets/slide_icon@2x.png">
      </div>
    </div> -->


    <!-- <div class="fifth-container" id="fifth">
      <div class="header-nav-container">
        <div class="header-logo-img" style="color:#fff">博树</div>
      </div> 
      <div class="about-container">
        <div class="about-english-txt">ABOUT US</div>
        <div class="about-chinese-txt">关于我们</div>
        <div class="about-img-container">
          <img v-for="(slide, index) in swiperSlides" :key="index" class="swiper-img" :src="slide">
        </div>
        <div class="about-introduce-txt">{{aboutContent}}</div>
        <div class="about-btn">
          <div style="font-size:0.5rem"><router-link to="About">了解更多>></router-link></div>
        </div>
      </div>
    </div> -->


    
  </article>
</template>

<script>
import UtilPaths from '../utils/UtilsPath.js'
import axios from 'axios'
// import 'video.js/dist/video-js.css'//视频播放器

export default {
  name: 'Home',
  data () {
    return {
      hideControll: false,
      swiperOption: {
        pagination: {
          el: '.swiper-pagination'
        },
        loop: {
          loop: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      selectType: ['1', '2', '3'],
      swiperSlides: [],
      hexinitems: [],
      topHexinItems: [],
      downHexinItems: [],
      projectItems: [],
      unFirstInfo: [],
      aboutContent: '',
      codeCheck: true,
      subCheck: true,
      xinwenitems: [
        { name: '行业动态' },
        { name: '项目动态' }
      ],
      zhaoshangitems: [
        { name: '招商方案' },
        { name: '已签约品牌' }
      ],
    }
  },
  methods: {
    clickNav (e) {
      let i = e.srcElement.attributes[1].value
      if (i === '1') {
        window.scrollTo(0, 0)
      } else if (i === '2') {
        window.scrollTo(0, 1040)
      } else if (i === '3') {
        window.scrollTo(0, 2089)
      } else if (i === '4') {
        window.scrollTo(0, 3138)
      } else {
        window.scrollTo(0, 3138)
      }
    },
    clickCoreNav (e) {
      let temp = parseInt(e.currentTarget.dataset.id)
      this.$router.push({ name: 'CoreBussiness', params: { id: temp, index: temp, hexinItems: this.$store.getters.getHexinItems[temp] } })
      window.scrollTo(0, 0)
    },
    clickFooterCoreNav (e) {
      let temp = parseInt(e.currentTarget.dataset.id)
      let tempIndex = (this.$data.hexinitems.length - this.$data.topHexinItems.length) + temp
      this.$router.push({ name: 'CoreBussiness', params: { id: tempIndex, index: tempIndex, hexinItems: this.$store.getters.getHexinItems[tempIndex] } })
      window.scrollTo(0, 0)
    },
    clickInvestmentNav (e) {
      let temp = parseInt(e.currentTarget.dataset.id)
      this.$router.push({ name: 'InvestmentInfo', params: { index: temp, zhaoshangItems: this.$store.getters.getZhaoshangItems[temp] } })
      window.scrollTo(0, 0)
    },
    closeVideo () {
      this.$data.hideControll = false
    },
    controllVideo () {
      this.$data.hideControll = true
    },
    checkInput () {
      let tempType = document.getElementById('type').value
      let tempName = document.getElementById('name').value
      let tempPhone = document.getElementById('phone').value
      let tempArea = document.getElementById('area').value
      let tempCheckCode = document.getElementById('checkcode').value
      if (tempType !== '' && tempName !== '' && tempPhone !== '' && tempArea !== '' && tempCheckCode !== '') {
        this.$data.subCheck = false
      } else {
        this.$data.subCheck = true
      }
    },
    inputValue (e) {
      if (e.length === 11) {
        this.$data.codeCheck = false
      } else {
        this.$data.codeCheck = true
      }
    },
    clickUNInfomationCenter (e) {
      let temp = parseInt(e.currentTarget.dataset.id)
      window.scrollTo(0, 0)
      if (temp === 0) {
        this.$router.push({ name: 'UNInfo', params: { index: temp } })
      } else {
        this.$router.push({ name: 'UNProject', params: { index: temp } })
      }
    },
    async sendCode () {
      let tempPhone = document.getElementById('phone').value
      if (tempPhone === null) {
        alert('请输入手机号')
      } else if (tempPhone.length !== 11) {
        alert('请输入正确的手机号')
      } else {
        let tempData = await axios.get(UtilPaths.getCheckCode + '/' + tempPhone)
        if (tempData.status === 200) {
          alert('发送成功')
        }
      }
    },
    async submitInfo () {
      let tempType = document.getElementById('type').value
      let tempName = document.getElementById('name').value
      let tempPhone = document.getElementById('phone').value
      let tempArea = document.getElementById('area').value
      let tempCheckCode = document.getElementById('checkcode').value
      let tempData = {
        typeName: tempType,
        linkman: tempName,
        address: tempArea,
        phone: tempPhone,
        code: tempCheckCode
      }
      let that = this
      await axios.post(UtilPaths.submitInfo, tempData).then(function (response) {
        if (response.data === 'success') {
          alert('提交成功！')
          that.$bvModal.hide('joinUs')
        } else {
          alert('验证码错误，请核实验证码信息！')
        }
      })
    },
    onPlayerPlay (player) {
    }
  },
  async created () {

    // 获取首页核心业务
    let tempNavInfos = await axios.get(UtilPaths.getNavInfos)
    // let list = tempNavInfos.data
    let list = [];
    tempNavInfos.data.forEach(item => {
      list.push({
        weburl:item.weburl,
      })
    });
    list[0].title = '新能源、餐饮'
    list[1].title = '旅游、政务'
    list[2].title = '购物、金融'
    list[3].title = 'AR'
    this.$data.hexinitems = list.concat([])
    this.$data.topHexinItems = list.splice(0,2)
    this.$data.downHexinItems = list.splice(0,4)
  },


  async mounted () {
    window.addEventListener('scroll', (e) => {
      if (this.$route.name === 'Home') {
        let tempOffset = window.pageYOffset
        var two = 1040
        var three = 2088
        var four = 3137
        var five = 3138
        if (two <= tempOffset && tempOffset < three) {
          for (let i in this.$refs) {
            if (i !== 'videoPlayer') {
              if (i === 'second') {
                this.$refs[i].classList.add('select')
              } else {
                this.$refs[i].classList.remove('select')
              }
            }
          }
        } else if (three <= tempOffset && tempOffset < four) {
          for (let i in this.$refs) {
            if (i !== 'videoPlayer') {
              if (i === 'third') {
                this.$refs[i].classList.add('select')
              } else {
                this.$refs[i].classList.remove('select')
              }
            }
          }
        } else if (four <= tempOffset && tempOffset < five) {
          for (let i in this.$refs) {
            if (i !== 'videoPlayer') {
              if (i === 'fourth') {
                this.$refs[i].classList.add('select')
              } else {
                this.$refs[i].classList.remove('select')
              }
            }
          }
        } else if (five <= tempOffset) {
          for (let i in this.$refs) {
            if (i !== 'videoPlayer') {
              if (i === 'fifth') {
                this.$refs[i].classList.add('select')
              } else {
                this.$refs[i].classList.remove('select')
              }
            }
          }
        } else if (tempOffset < two) {
          for (let i in this.$refs) {
            if (i !== 'videoPlayer') {
              if (i === 'first') {
                this.$refs[i].classList.add('select')
              } else {
                this.$refs[i].classList.remove('select')
              }
            }
          }
        }
      }
    })
    let temp = []
    let tempCompanyInfo = await axios.get(UtilPaths.getCompanyList)
    for (let i = 0; i < tempCompanyInfo.data.companyImgList.length; i++) {
      temp[i] = tempCompanyInfo.data.companyImgList[i].url
    }
    this.$data.swiperSlides = temp
    this.$data.aboutContent = tempCompanyInfo.data.content
  },
  computed: {
    player () {
      return this.$refs.videoPlayer.player
    }
  },
  components: {
    // videoPlayer
  }
}
</script>

<style scoped>
#image{

}
.home-main-container{
  display: flex;
  flex-direction: column;
}
*{
  font-family: MicrosoftYaHei;
}
.side-nav{
  position: fixed;
  top: 45%;
  right: 20px;
  z-index: 1;
}
.side-nav ul{
  text-align: center;
  list-style: none;
}
.side-nav ul li{
  display: block;
  line-height: 1.45rem;
  padding: 8px 0;
}
.side-nav ul li a{
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.first-title{
  font-size: 40pt;
  letter-spacing: 6px;
  color: #fff;
  position: absolute;
  top: 15.21rem;
}
.default{
  background-color: #85939b;
}
.select{
  background-color: #68d5d0;
}
.first-container{
  background: url('../assets/indexBanner.jpeg');
  display: flex;
  width: 100%;
  height: 1040px;
  justify-content: center;
  position: relative;
  background-size: 100% 100%;
}
.slide-icon{
  width: 48px;
  height: 48px;
  position: absolute;
  left:50%;
  bottom: 30px;
}
.zhezhao{
  width: 100%;
  height: 100%;
  /* background-color: #333333; */
  opacity: 0.9;
  z-index: 2;
}
.second-container{
  background: #fff;
  height: 1049px;
  display: flex;
  position: relative;
  text-align: center;
  /* justify-content: center; */
}
.second-business-txt-container{
  display: flex;
  position: absolute;
  top: 275px;
  left: 21.1vw;;
  flex-direction: column;
}
.second-business-img-container{
  display: flex;
  flex-direction: column;
  /* position: absolute; */
  margin-left: 44vw;
  margin-top: 163px;
}
.img-header-container{
  display: flex;
  /* margin-left: 10vw; */
}
.img-footer-container{
  display: flex;
  margin-top: 40px;
  /* margin-left: 10vw; */
}
.first-img{
  width: 300px;
  height: 300px;
  margin-right: 40px;
  background: #68d5d0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.business-img{
  width: 3.92rem;
  height: 3.92rem;
}
.business-txt{
  font-size: 24px;
  color: #fff;
  letter-spacing: 3.5px;
}
.first-img:hover{
  transform: scale(1.1,1.1);
  cursor: pointer;
}
.last-img{
  width: 300px;
  height: 300px;
  margin-right: 40px;
  background: #2d3e50;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.last-img:hover{
  transform: scale(1.1,1.1);
  cursor: pointer;
}
.line{
  border-bottom: 2px #68d5d0 solid;
  margin-top: 0.57rem;
  width: 1.85rem;
}
.core-english{
  text-align: left;
  color: #999999;
  font-size: 1.35rem;
}
.core-chinese{
  /* margin-top: rem; */
  color: #333333;
  font-size: 1.14rem;
  text-align: start;
  letter-spacing: 6px;
}
.business-contain{
  margin-top: 2.5rem;
  text-align: start;
}
.contain-txt{
  color: #999999;
  font-size: 0.85;
  line-height: 1.5rem;
  letter-spacing: 2px;
}
.third-container{
  position: relative;
  background: url('https://pc.hnchengyue.com/static/image/bigbackground/bgnews.png');
  background-size: 100% 100%;
  height: 1049px;
  display: flex;
  justify-content: center;
}
.info-main-container{
  display: flex;
  flex-direction: row;
  position: relative;
  width:80vw;
  left:10vw;
  top:2vh;
} 
.info-container{
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  width: 650px;
  height: 560px;
  background: #68d5d0;
  top: 231px;
  left: 90px;
  text-align: center;
}
.detail-info{
  display: flex;
  margin-left: 1.78rem;
  margin-bottom: 1.42rem;
}
.time-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  background: #fff;
}
.time-day{
  font-size: 1.78rem;
  margin-bottom: 0.3rem;
  color: #68d5d0;
  height: 1.78rem;
}
.time-date{
  font-size: 0.5rem;
  color: #68d5d0;
}
.info-txt-container{
  width: 15rem;
  margin-left: 0.71rem;
  display: flex;
  flex-direction: column;
  text-align: justify;
}
.txt-title{
  font-size: 24px;
  color: #fff;
}
.txt-content{
  font-size: 18px;
  color: #fff;
  margin-top: 0.2rem;
}
.new-info-container{
  position: relative;
  width: 400px;
  height: 400px;
  background: #2D3E50;
  margin-top: 320px;
  left: 670px;
  display: flex;
  flex-direction: column;
  padding: 3% 2.5%;
  text-align: justify;
}
.info-time-container{
  display: flex;
  position: relative;
  margin-bottom: 0.5rem;
}
.center-info-container{
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 325px;
  left: 1200px;
}
.fourth-container{
  background: #fff;
  height: 1049px;
  position: relative;
  display: flex;
  justify-self: center;
  text-align: center;
}
.invest-container{
  /* position: absolute; */
  display: flex;
  width:60%;
  height:80%;
  margin-left:5%;
  margin-top:10%;
  flex-direction: row;
 justify-items: center;
  top: 12vh;
}
.core-container-left{
  margin-top:10%;
  width:40%;
}
.join-us-txt{
  text-align: left;
  color: #fff;
  font-size: 0.85rem;
  letter-spacing: 6px;
}
.join-us-btn{
  width: 150px;
  height: 40px;
  background: #68d5d0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 21px;
}
.invest-info-container{
  width: 26rem;
  height: 26rem;
  /* margin-top: 5%; */
  /* width:60%; */
}
.nuclearBg{
  width:auto;
  height:auto;
  width:100%;
  height:100%;
  background: url('../assets/vr.png');
  background-size:cover;
  background-position-x: right;
}
.swiper-slide-container{
  width: 300px;
  margin-right: 50px;
}
.swiper-img{
  width: 300px;
  height: 300px;
  margin-left: 25px;
  margin-right: 25px;
}
.fifth-container{
  background: url('https://pc.hnchengyue.com/static/image/bigbackground/bgaboutus.png');
  height: 1049px;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: center;
}
.about-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  align-items: center;
  top: 183px;
}
.about-english-txt{
  font-size: 38px;
  letter-spacing: 1px;
  color: #fff;
}
.about-chinese-txt{
  font-size: 32px;
  letter-spacing: 6px;
  margin-top: 20px;
  color: #fff;
}
.about-img-container{
  height: 300px;
  margin-top: 53px;
  /* background: #68d5d0; */
}
.about-introduce-txt{
  margin-top: 50px;
  font-size: 24px;
  opacity: 0.8;
  letter-spacing: 6px;
  text-align: justify;
  color: #fff;
  width: 971px;
}
.more-btn{
  width: 5rem;
  height: 1.55rem;
  line-height: 1.55rem;
  text-align: center;
  border: 1px #fff solid;
  border-radius: 5px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.5rem;
  margin-left: 1.78rem;
  cursor: pointer;
}
.more-btn:hover{
  color: #68d5d0;
}
.more-btn-active:hover{
  color: #6986a5;
}
.about-btn{
  width: 5rem;
  height: 1.55rem;
  border: 1px #fff solid;
  border-radius: 5px;
  color: #fff;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 1rem;
  bottom: -2rem;
  text-align: center;
  line-height: 1.55rem;
}
.nav-container{
  display: flex;
  justify-content: space-around;
  position: absolute;
  right: 49.7%;
  top: 17.5pt;
}
.header-nav-container{
  display: flex;
  /* height: 60px; */
  align-items: center;
  position: absolute;
  width: 100%;
  color: #fff;
  z-index: 2;
  font-size: 1rem;
}
.header-logo-img{
  margin-top:1rem;
  margin-left: 60px;
  letter-spacing: 0.28em;
}
.nav-item{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 31.5pt;
  font-family: MicrosoftYaHei;
  position: relative;
}
.linone{
  list-style: none;
  border: 1px #fff solid;
}
.li1{
  visibility: hidden;
  position: absolute;
  width: 200px;
  top: 1.07rem;
}
li:hover .li1{
  visibility: visible;
}
.footer-container{
  position: relative;
  display: flex;
  flex-direction: column;
  background:#2d3e50;
  height: 236pt;
}
.footer-message-container{
  margin-top: 54pt;
  display: flex;
  color: #fff;
}
.footer-message-left-container{
  display: flex;
  margin-left: 450pt;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.footer-message-middle-container{
  margin-left: 220pt;
}
.footer-message-right-container{
  margin-left: 130pt;
}
.logo-txt{
  font-size: 16.29pt;
}
.message-txt{
  font-size: 12pt;
}
.copyright{
  width:362pt;
  height:11.5pt;
  margin-top:59pt;
}
.btn-active{
  background-color:#68d5d0;
}
</style>
