<template>
  <div class="core-content-container">
<!--    <div class="content-img">
      <b-carousel
        id="carousel-fade"
        style="text-shadow: 0px 0px 2px #000"
        fade
        indicators
        :interval="3000"
        img-width="752"
        img-height="752"
      >
      <b-carousel-slide :img-src="item.url" v-for="(item, index) in swiperSlides" :key="index" img-width="752"
        img-height="752"></b-carousel-slide>
      </b-carousel>
    </div>-->
    <div class="content-txt-container">
      <div class="txt-container">
        <div class="txt-header">{{swiperContent.title}}</div>
        <div class="txt-content">{{swiperContent.allcontent}}</div>
      </div>
    </div>
    <div class="content-img">
      <img :src="swiperSlides[index].url" width="632px" height="632px" >
    </div>
  </div>
</template>



<script>
export default {
  data () {
    return {
      index:0,
      swiperSlides: [],
      swiperContent: []
    }
  },
    methods:{
      imgclick(){
         if(this.index>=3){
             this.index=0;
         } else {
             this.index = (this.index+1)%3;
         }
      }
    },
  props: {
    indNum: Number
  },
  created () {
    setInterval(this.imgclick,3000);
    if (this.$route.params.hexinItems) {
      let tswiperSlides = JSON.stringify(this.$route.params.hexinItems.nuclearImgList)
      let tswiperContent = JSON.stringify(this.$route.params.hexinItems)
      sessionStorage.setItem('swiperSlides', tswiperSlides)
      sessionStorage.setItem('swiperContent', tswiperContent)
      this.$data.swiperSlides = this.$route.params.hexinItems.nuclearImgList
      this.$data.swiperContent = this.$route.params.hexinItems
    } else {
      let tswiperSlides = sessionStorage.getItem('swiperSlides')
      let tswiperContent = sessionStorage.getItem('swiperContent')
      this.$data.swiperSlides = JSON.parse(tswiperSlides)
      this.$data.swiperContent = JSON.parse(tswiperContent)
    }
  },
  watch: {
    indNum (a, b) {
      this.$data.swiperSlides = this.$store.getters.getHexinItems[this.$props.indNum].nuclearImgList
      this.$data.swiperContent = this.$store.getters.getHexinItems[this.$props.indNum]
    },
    name () {
      this.$data.swiperSlides = this.$route.params.hexinItems.nuclearImgList
      this.$data.swiperContent = this.$route.params.hexinItems
      this.$emit('transfer', this.$route.params.id)
    }
  },
  computed: {
    name () {
      return this.$route.params.id
    }
  }
}
</script>

<style scoped>
  /* .content-img {
    width:632px;
    height: 632px;
  } */

.core-content-container {
  display: flex;
  margin-top: 3.17rem;
  margin-bottom: 3.17rem;

}
.swiper-img{
  width: 10.5rem;
  height: 10.6rem;
}
.content-txt-container {
  z-index:100;
  display: flex;
  padding: 4.2rem 2rem 4.2rem 2rem;
  width: 632px;
  height: 632px;
  background: #F6F6F6;
  border-radius: 0 5px 5px 0;
}
.txt-container {
  display: flex;
  flex-direction: column;
  text-align: start;
  background: #f6f6f6;
  /* line-height: 16px; */
  /* width: 16rem; */
  text-align: justify;
}
.txt-header {
  color: #333333;
  font-size: 32px;
  letter-spacing: 6px;
}
.txt-content {
  color: #999999;
  font-size: 24px;
  letter-spacing: 0.16em;
  margin-top: 2rem;
  line-height: 40px;
  text-indent: 45px;
}


</style>
