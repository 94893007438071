
<template>
  <div class="about-main-container">
    <!-- <div class="about-header-container">
      <div class="header-title">关于我们</div>
      <div class="header-slogan">{{slogan}}</div>
    </div>
    <div class="about-body-container">
      <div class="body-header-container">
        <div class="header-txt-container">
          <div class="header-txt-title">企业简介</div>
          <div class="header-txt-content">{{companyList.companyAll}}</div>
        </div>
        <img class="header-img-container" :src="companyList.bigurl">
      </div>
      <div class="parter-name">合作伙伴</div>
      <div class="parter-logo" >
          <img class="parter-img" v-for="(item,index) in imgList" :key="index" :src="item.url">
      </div>
    </div> -->
    <img id="image" style="width:100%;height: 120rem" src="../assets/app.png" alt="">
  </div>
</template>

<script>
import UtilsPath from '../utils/UtilsPath'
import axios from 'axios'
export default {
  data () {
    return {
      imgList: [],
      slogan: '开创商业新模式 打造业态新格局',
      companyList: []
    }
  },
  created () {
    window.scrollTo(0, 0)
  },
  async mounted () {
    let companyList = await axios.get(UtilsPath.getCompanyList)
    let temp = await axios.get(UtilsPath.getParterLogo)
    this.$data.imgList = temp.data
    this.$data.companyList = companyList.data
    // console.log(companyList.data)
  }
}
</script>

<style scoped>
.about-main-container {
  display: flex;
  flex-direction: column;
}
.about-header-container {
  background: url('https://pc.hnchengyue.com/static/image/bigbackground/aboutusbigbg.png');
  background-size: 100% 23.92rem;
  height: 23.92rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.header-title {
  color: #fff;
  font-size: 2.85rem;
  letter-spacing: 6px;
}
.header-slogan {
  /* width: 18.67rem; */
  color: #fff;
  font-size: 1rem;
  letter-spacing: 6px;
  margin-top: 61px;
  opacity: 0.8;
  border-bottom: 1px #fff solid;
}
.about-body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.body-header-container {
  width: 70.2vw;
  height: 100vh;
  position: relative;
  margin-top: 3.57rem;
  /* margin-left: 11.21rem;  */
}
.header-txt-container {
  display: flex;
  flex-direction: column;
  text-align: start;
  width: 20rem;
  height: 20rem;
  position: absolute;
  background: #68d5d0;
  z-index: 2;
  padding: 2.92rem 2.46rem;
}
.header-txt-title{
  color: #fff;
  font-size: 1.14rem;
}
.header-txt-content{
  font-size: 0.5rem;
  color: #fff;
  margin-top: 1rem;
  letter-spacing: 0.1rem;
  line-height: 1rem;
  text-align: justify;
}
.header-img-container {
  width:64.3vw;
  height: 78.4vh;
  position: absolute;
  background-size: 1116px 836px;
  top: 6.46rem;
  left: 4.03rem;
}
.parter-name {
  margin-top: 101px;
  color: #333333;
  font-size: 50px;
  letter-spacing: 6px;
}
.parter-logo {
  margin-top: 62px;
  padding: 0 200px 0 200px;
  margin-bottom: 120px;
  text-align: left;
}
.parter-img{
  width:23vw;
  height:19.45vh;
  margin-right:34px;
  margin-top:20px
}
</style>
