import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import JwPagination from 'jw-vue-pagination'

Vue.component('jw-pagination', JwPagination)

Vue.config.productionTip = false

Vue.use(BootstrapVue)

if (process.browser) {
  const VueAwesomeSwiper = require('vue-awesome-swiper/dist/ssr')
  Vue.use(VueAwesomeSwiper)
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
