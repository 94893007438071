<template>
  <div class="UNinfo-main-container">
    <div class="UNinfo-header-container">
      <div class="header-title">新闻中心</div>
      <div class="header-slogan">挖掘行业价值 引领区域发展</div>
    </div>
    <div class="UNinfo-body-container">
      <div class="body-header-container">
        <div
          class="body-header-nav"
          :class="item.class"
          :data-id="index"
          :ref="index"
          @click="clickNav"
          v-for="(item,index) in UNInformationItems"
          :key="index"
        >{{item.name}}</div>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import UtilPaths from '../utils/UtilsPath'
import axios from 'axios'
export default {
  data () {
    return {
      UNInformationItems: [{ name: '行业资讯' }, { name: '项目动态' }],
      indexInfos: 0
    }
  },
  methods: {
    clickNav (e) {
      for (let i in this.$refs) {
        if (this.$refs[i][0].classList.contains('body-header-nav-clicked')) {
          this.$refs[i][0].classList.remove('body-header-nav-clicked')
        }
      }
      e.currentTarget.classList.add('body-header-nav-clicked')
      this.$data.indexInfos = parseInt(e.srcElement.dataset.id)
      let tIndex = JSON.stringify(this.$data.indexInfos)
      sessionStorage.setItem('UNIndex', tIndex)
      if (e.srcElement.innerText === '行业资讯') {
        this.$router.push({ name: 'UNInfo', params: { index: e.srcElement.dataset.id } })
      } else if (e.srcElement.innerText === '项目动态') {
        this.$router.push({ name: 'UNProject', params: { index: e.srcElement.dataset.id } })
      }
    }
  },
  async created () {
    let tempInfos = await axios.get(UtilPaths.getUNInfo + '/' + 1 + '/' + 4)
    this.$store.commit('setUNInfoItems', { xinwenItems: tempInfos.data })
    this.$store.commit('setShowUNInfoItem', { showXinwenItems: tempInfos.data.records })
  },
  mounted () {
    if (this.$route.params.index) {
      let tIndex = JSON.stringify(this.$route.params.index)
      sessionStorage.setItem('UNIndex', tIndex)
      this.$refs[tIndex][0].classList.add('body-header-nav-clicked')
    } else {
      let tIndex = sessionStorage.getItem('UNIndex')
      this.$refs[tIndex][0].classList.add('body-header-nav-clicked')
    }
  },
  watch: {
    name () {
      for (let i in this.$refs) {
        if (this.$refs[i][0].classList.contains('body-header-nav-clicked')) {
          this.$refs[i][0].classList.remove('body-header-nav-clicked')
        }
      }
      this.$refs[this.$route.params.index][0].classList.add('body-header-nav-clicked')
    }
  },
  computed: {
    name () {
      return this.$route.params.index
    }
  }
}
</script>

<style scoped>
.UNinfo-main-container{
  display: flex;
  flex-direction: column;
}
.UNinfo-header-container{
  background: url('https://pc.hnchengyue.com/static/image/bigbackground/newsbigbg.png');
  background-size: 100% 23.92rem;
  height: 23.92rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.header-title {
  color: #fff;
  font-size: 2.85rem;
  letter-spacing: 6px;
}
.header-slogan {
  color: #fff;
  font-size: 22.64rem;
  font-size: 1.14rem;
  letter-spacing: 6px;
  margin-top: 2.67rem;
  opacity: 0.8;
  border-bottom: 1px #fff solid;
}
.UNinfo-body-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.body-header-container {
  display: flex;
  margin-top: 2.21rem;
  height: 2.85rem;
}
.body-header-nav{
  width: 10.71rem;
  height: 2.85rem;
  font-size: 1.14rem;
  margin-right: 1.13rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.body-header-nav:hover{
  font-size: 1.2rem;
  background: #b5b5b5;
  color: #fff;
}
.body-header-nav-clicked{
  color:#fff;
  background: #2d3e50;
}
</style>
