import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    hexinItems: [],
    zhaoshangItems: [],
    showZhaoshangItems: [],
    unInfoItems: [],
    showUNInfoItems: [],
    unAllInfoItems: [],
    unProjectItems: [],
    showUNProjectItems: [],
    unAllProjectItems: []
  },
  getters: {
    getHexinItems: (state, getters) => {
      return state.hexinItems
    },
    getZhaoshangItems: (state, getters) => {
      return state.zhaoshangItems
    },
    getShowZhaoshangItems: (state, getters) => {
      return state.showZhaoshangItems
    },
    getUNInfoItems: (state, getters) => {
      return state.unInfoItems
    },
    getShowUNInfoItems: (state, getters) => {
      return state.showUNInfoItems
    },
    getUNAllInfoItems: (state, getters) => {
      return state.unAllInfoItems
    },
    getUNProjectItems: (state, getters) => {
      return state.unProjectItems
    },
    getShowUNProjectItems: (state, getters) => {
      return state.showUNInfoItems
    },
    getUNAllProjectItems: (state, getters) => {
      return state.unAllProjectItems
    }
  },
  mutations: {
    setHexinItems (state, payload) {
      state.hexinItems = payload.hexinItems
    },
    setZhaoshangItems (state, payload) {
      state.zhaoshangItems = payload.zhaoshangItems
    },
    setShowZhaoshangItems (state, payload) {
      state.showZhaoshangItems = payload.showZhaoshangItems
    },
    setUNInfoItems (state, payload) {
      state.unInfoItems = payload.xinwenItems
    },
    setShowUNInfoItem (state, payload) {
      state.showUNInfoItems = payload.showXinwenItems
    },
    setUNAllInfoItems (state, payload) {
      state.unAllInfoItems = payload.unAllInfoItems
    },
    setUNProjectItems (state, payload) {
      state.unProjectItems = payload.unProjectItems
    },
    setShowUNProjectItem (state, payload) {
      state.showUNProjectItems = payload.showUNProjectItems
    },
    setUNAllProjectItems (state, payload) {
      state.unAllProjectItems = payload.unAllProjectItems
    }
  },
  actions: {
  },
  modules: {
  }
})
