<template>
  <div class="core-main-container">
    <div class="core-header-container">
      <div class="header-title">核心业务</div>
      <div class="header-slogan">聚合新能源产业链  集娱乐体验新赛道</div>
    </div>
    <div class="core-body-container">
      <div class="body-header-container">
        <div class="body-header-nav" :class="item.class" :data-id="index" :ref="index" @click="clickNav" v-for="(item,index) in hexinNav.data" :key="index">{{item.title}}</div>
      </div>
      <CoreBussinessContent :indNum="indexInfos" @transfer="setInfos"/>
    </div>
  </div>
</template>

<script>
import CoreBussinessContent from '../components/CoreBussiness/coreBussinessContent.vue'
export default {
  data () {
    return {
      hexinNav: [],
      indexInfos: 0
    }
  },
  methods: {
    clickNav (e) {
      for (let i in this.$refs) {
        if (this.$refs[i][0].classList.contains('body-header-nav-clicked')) {
          this.$refs[i][0].classList.remove('body-header-nav-clicked')
        }
      }
      e.currentTarget.classList.add('body-header-nav-clicked')
      this.$data.indexInfos = parseInt(e.srcElement.dataset.id)
      let tswiperSlides = JSON.stringify(this.$store.getters.getHexinItems[this.$data.indexInfos].nuclearImgList)
      let tswiperContent = JSON.stringify(this.$store.getters.getHexinItems[this.$data.indexInfos])
      let tIndex = JSON.stringify(this.$data.indexInfos)
      sessionStorage.setItem('swiperSlides', tswiperSlides)
      sessionStorage.setItem('swiperContent', tswiperContent)
      sessionStorage.setItem('coreIndex', tIndex)
    },
    setInfos (item) {
      for (let i in this.$refs) {
        if (this.$refs[i][0].classList.contains('body-header-nav-clicked')) {
          this.$refs[i][0].classList.remove('body-header-nav-clicked')
        }
      }
      this.$refs[item][0].classList.add('body-header-nav-clicked')
    }
  },
  components: {
    CoreBussinessContent
  },
  created () {
    this.$data.hexinNav.data = this.$store.getters.getHexinItems
    this.$data.indexInfos = this.$route.params.index
  },
  mounted () {
    let tIndex = JSON.stringify(this.$route.params.index)
    if (tIndex) {
      sessionStorage.setItem('coreIndex', tIndex)
    } else {
      tIndex = sessionStorage.getItem('coreIndex')
    }
    this.$refs[tIndex][0].classList.add('body-header-nav-clicked')
  },
  watch: {
    name () {
      for (let i in this.$refs) {
        if (this.$refs[i][0].classList.contains('body-header-nav-clicked')) {
          this.$refs[i][0].classList.remove('body-header-nav-clicked')
        }
      }
      if (this.$route.params.index) {
        let tIndex = JSON.stringify(this.$route.params.index)
        sessionStorage.setItem('coreIndex', tIndex)
        this.$refs[this.$route.params.index][0].classList.add('body-header-nav-clicked')
      } else {
        this.$refs[this.$route.params.index][0].classList.add('body-header-nav-clicked')
      }
    }
  },
  computed: {
    name () {
      return this.$route.params.index
    }
  }
}
</script>

<style scoped>
.core-main-container {
  display: flex;
  flex-direction: column;
}
.core-header-container {
  background: url('https://pc.hnchengyue.com/static/image/bigbackground/nuclearbigbg.png');
  background-size: 100% 23.92rem;
  height: 23.92rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.header-title {
  color: #fff;
  font-size: 2.85rem;
  letter-spacing: 6px;
}
.header-slogan {
  /* width: 22.64rem; */
  color: #fff;
  font-size: 1.14rem;
  letter-spacing: 6px;
  margin-top: 2.67rem;
  opacity: 0.8;
  border-bottom: 1px #fff solid;
}
.core-body-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.body-header-container {
  display: flex;
  margin-top: 2.21rem;
  height: 2.85rem;
}
.body-header-nav{
  width: 10.71rem;
  height: 2.85rem;
  font-size: 1.14rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 1.13rem;
}
.body-header-nav:hover{
  font-size: 1.2rem;
  background: #b5b5b5;
  color: #fff;
}
.body-header-nav-clicked{
  color:#fff;
  background: #2d3e50;
}
</style>
