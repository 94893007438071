<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  created () {
    let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth
    let htmlDom = document.documentElement
    htmlDom.style.fontSize = htmlWidth / 75 + 'px'
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
   width: 100%;
        height: auto;
        /* overflow-y: scroll; */
}

p {
  margin-top: 0.79rem !important;
  font-size: 0.64rem !important;
  margin-bottom: 0.6rem !important;
}

a {
  text-decoration: none !important;
  color: #fff !important;
}
a:hover{
  color: #68d5d0 !important;
}
</style>
