<template>
    <article class="footer-container">
    <div class="footer-message-container">
        <div class="footer-message-left-container">
          <div class="logo-txt">博树</div>
          <div class="message-container">
            <img class="message-img" src="https://pc.hnchengyue.com/static/image/smallbtn/phone_icon.png">
            <div class="message-txt">0898-88337998</div>
          </div>
          <div class="message-container">
            <img class="message-img" src="https://pc.hnchengyue.com/static/image/smallbtn/add_ico.png">
            <div class="message-txt">海南省三亚市吉阳区海润路</div>
          </div>
        </div>
        
        <div class="footer-message-right-container">
          <div class="message-txt">微信公众号</div>
          <img class="wxImg" src="../../assets/broadtreeCode.png">
        </div>
        
    </div>
    <div>
      <span class="footer-copyright" @click="see">
        <!-- <img class="copyright" :src="toWebsite"> -->
        <div @click="seeCopy">版权所有 海南博树创造科技有限公司 | 琼ICP备17000802号-2</div>
      </span>
    </div>
    </article>
</template>

<script>
import UtilPaths from '../../utils/UtilsPath'
import axios from 'axios'
export default {
  data () {
    return {
      WxImg: '',
      AppImg: '',
      toWebsite:'https://pc.hnchengyue.com/ static/image/bigbackground/footinfo.png ',
      footerInfo: ''
    }
  },
  async created () {
    let tempData = await axios.get(UtilPaths.getWxCode)
    let tempAppData = await axios.get(UtilPaths.getAppCode)
    let tempFooterInfo = await axios.get(UtilPaths.getFooterInfo)
    console.log(tempFooterInfo)
    this.$data.WxImg = tempData.data[0].url
    this.$data.AppImg = tempAppData.data[0].url
    this.$data.footerInfo = tempFooterInfo.data
  },
  methods:{       
    seeCopy(){
       window.location.href = 'https://beian.miit.gov.cn/'
    } ,
  //  see () {  
  //    window.location.href = 'https://beian.miit.gov.cn/#/Integrated/index'   //获取传入的数据，若传入的数据时正确的网址，就可以调到外部网站
  //   }
  }
}
</script>

<style scoped>
.footer-container{
  position: relative;
  display: flex;
  flex-direction: column;
  /* height:12.63vh; */
  width:100%;
  background:#2d3e50;
}
.footer-message-container{
  margin-top: 3.75rem;
  display: flex;
  justify-content: space-around;
  color: #fff;
}
.footer-message-left-container{
  display: flex;
  /* margin-left: 14.28rem; */
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.footer-message-middle-container{
  margin-left: 8.57rem;
}
.footer-message-right-container{
  margin-left: 5.78rem;
}
.logo-txt{
  font-size: 1.14rem;
  margin-bottom: 0.66rem;
}
.message-container{
  display: flex;
}
.message-img{
  width: 1.28rem;
  height: 1.28rem;
  background-size: 1.28rem 1.28rem;
}
.message-txt{
  font-size: 0.85rem;
}
.footer-copyright{
  display: flex;
  justify-content: center;
  margin: 1.66rem 0;
  color: #fff;
}
.copyright{
  width:362pt;
  height:11.5pt;
  position: absolute;
  bottom: 0.25rem;
}
.wxImg{
  width:6.1rem;
  height:6.1rem;
  background-size: 6.1rem 6.1rem;
  margin-top: 0.18rem;
}
</style>
