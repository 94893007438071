<template>
  <article class="main-container">
    <div class="header-nav-container">
      <div class="header-logo-img" style="color:#fff"></div>
      <div class="nav-container">
        <div class="nav-item">
          <router-link to="/">首页</router-link>
        </div>
        <!-- <li class="nav-item">
          <div @click="clickCoreNav" :data-id=0>核心业务</div>
          <ul class="li1">
            <li class="linone" v-for="(item,index) in hexinitems" @click="clickCoreNav" :data-id="index" :key="index">{{item.title}}</li>
          </ul>
        </li>
        <li class="nav-item">
          <div @click="clickInvestmentNav" :data-id=0>项目招商</div>
          <ul class="li1">
            <li class="linone" v-for="(item,index) in zhaoshangitems" @click="clickInvestmentNav" :data-id="index" :key="index">{{item.name}}</li>
          </ul>
        </li>
        <li class="nav-item">
          <div @click="clickUNInfomationCenter" :data-id=0>新闻中心</div>
          <ul class="li1">
            <li class="linone" v-for="(item,index) in xinwenitems" @click="clickUNInfomationCenter" :data-id="index" :key="index">{{item.name}}</li>
          </ul>
        </li> -->
        <div class="nav-item">
          <router-link to="/about">案例</router-link>
        </div>
        <!-- <div class="nav-item">
          <router-link to="/appDesc">诚越app</router-link>
        </div> -->
      </div>
    </div>
    <router-view></router-view>
    <Footer />
  </article>
</template>

<script>
import UtilPaths from '../utils/UtilsPath'
import Footer from '../components/footer/footer.vue'
import axios from 'axios'
export default {
  data () {
    return {
      hexinitems: [],
      xinwenitems: [{ name: '行业资讯' }, { name: '项目动态' }],
      zhaoshangitems: [{ name: '招商方案' }, { name: '已签约品牌' }]
    }
  },
  async mounted () {
    let tempNavInfos = await axios.get(UtilPaths.getNavInfos)
    let tempZhaoshangInfos = await axios.get(UtilPaths.getBussinessParent)
    this.$data.hexinitems = tempNavInfos.data
    this.$store.commit('setZhaoshangItems', { zhaoshangItems: tempZhaoshangInfos.data })
    this.$store.commit('setHexinItems', { hexinItems: tempNavInfos.data })
  },
  methods: {
    clickCoreNav (e) {
      let temp = parseInt(e.currentTarget.dataset.id)
      this.$router.push({ name: 'CoreBussiness', params: { id: temp, index: temp, hexinItems: this.$store.getters.getHexinItems[temp] } })
    },
    clickInvestmentNav (e) {
      let temp = parseInt(e.currentTarget.dataset.id)
      if (temp === 0) {
        this.$router.push({ name: 'InvestmentInfo', params: { index: temp, zhaoshangItems: this.$store.getters.getZhaoshangItems[temp] } })
      } else {
        this.$router.push({ name: 'ZiInverstmentParter', params: { index: temp } })
      }
    },
    clickUNInfomationCenter (e) {
      let temp = parseInt(e.currentTarget.dataset.id)
      if (temp === 0) {
        this.$router.push({ name: 'UNInfo', params: { index: temp } })
      } else {
        this.$router.push({ name: 'UNProject', params: { index: temp } })
      }
    }
  },
  components: {
    Footer
  }
}
</script>

<style scoped>
.main-container {
  /* display: flex; */
  flex-direction: column;
}
.nav-container{
  display: flex;
  justify-content: space-around;
  position: absolute;
  right: 96px;
  height: 0.85rem;
  /* top: 17.5pt; */
  font-size: 24px;
}
.header-nav-container {
  display: flex;
  height: 3.03rem;
  align-items: center;
  position: absolute;
  width: 100%;
  color: #fff;
  z-index: 2;
}
.header-logo-img {
  margin-left: 60px;
    letter-spacing: 0.28em;
    width: 200px;
    height: 63px;
    background: url('../assets/logob.png');
    background-size: 130%;
  /* background-image:url('../logo1.png'); */
}
.nav-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 31.5pt;
  font-family: MicrosoftYaHei;
  position: relative;
  cursor: pointer;
}
.linone {
  list-style: none;
  color: #fff;
}
.li1 {
  visibility: hidden;
  position: absolute;
  width: 200px;
  top: 30px;
  font-size: 0.57rem;
}
.nav-item:hover{
  color: #68d5d0;
}
.linone:hover{
  color: #68d5d0;
}
li:hover .li1 {
  visibility: visible;
}
li .li1:hover{
  visibility: visible;
}
</style>
