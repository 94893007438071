 //let testPath = 'https://test.wonderfulbox.club'
//  let textPathAfter = 'https://pc.hnchengyue.com/images/energyweb.wonderfulbox.club/static/image/'
 //let productionPath = 'https://website.hnchengyue.com/'
 let productionPath = 'https://pc.hnchengyue.com/server/'
 let domain = productionPath

 export default {
    getNavInfos: domain + 'nuclear/getList', // 获取导航栏信息
    getCompanyList: domain + 'company/one', // 获取公司内容
    getParterLogo: domain + 'partnerImg/allasc', // 获取合作伙伴LOGO
    getBussinessParent: domain + 'businessParent/allDesc', // 获取招商总内容
    getBussinessDetails: domain + 'businessDetail/getByPidPage', // 获取招商详细信息
    getUNInfo: domain + 'tradeNews/listPage', // 获取行业资讯内容
    getProjectInfo: domain + 'parkNews/listPage', // 获取项目动态
    getWxCode: domain + 'wecode/getOne', // 获取公众号二维码
    getProjectAllInfo: domain + 'parkNews/getList', // 获取全部项目新闻
    getUNAllInfo: domain + 'tradeNews/getList', // 获取全部行业资讯
    getAppCode: domain + 'appcode/getOne', // 获取APP二维码
    getCheckCode: domain + 'joinUs/sendCode', // 获取验证码
    submitInfo: domain + 'joinUs/join', // 上传信息
    getFooterInfo: domain + 'foot/msg', // 获取页脚信息
    getSignatureInfo: domain + 'brand/allasc', // 获取签约品牌
    getHomePageProject: domain + 'parkNews/webNews' // 获取首页新闻项目信息
 }