<template>
  <div class="content">
      <img src="../assets/app.png" alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    img{
        width: 100%;
        height: 120rem;
    }
</style>